import { converters as tableConverters } from 'store/table';
import { IAdminOrder } from 'types/income';
import { loaders } from './admin-orders.loaders';
import { AdminOrder } from './admin-orders.types';

const convertAdminOrder = (order: IAdminOrder): AdminOrder => {
  return {
    id: order.id,
    status: order.status,
    price: order.marketplaceItemPrice,
    currency: order.marketplaceItem.currency,
    orderNr: order.orderNr,
    createdAt: order.creationDate,
    admin: order.admin?.id
      ? {
          id: order.admin.id,
          fullName: [order.admin?.firstName, order.admin?.lastName]
            .filter(Boolean)
            .join(' '),
        }
      : null,
    redeemMethods: {
      code: order.couponCode ?? '',
      barCode: order.barcode ?? '',
      pin: order.pin ?? '',
      qrCode: order.qrcode ?? '',
      serial: order.serial ?? '',
      url: order.url ?? '',
    },
    couponFileId: order.couponFileId ?? '',
    commentForEmployee: order.commentForEmployee ?? '',
    commentForAdmin: order.commentForAdmin ?? '',
    shopItem: {
      id: order.marketplaceItem.id,
      name: order.marketplaceItem.name,
      sku: order.marketplaceItem.sku,
      additionalInfo: order.marketplaceItem.supplier?.additionalInfo ?? '',
      countries: order.marketplaceItem.countries,
      price: order.marketplaceItemPrice,
      priceDescription: order.marketplaceItemPriceDescription ?? '',
      vatRate: (order.marketplaceItemVatRate ?? 0) * 0.01,
      vatAmount: order.marketplaceItemVatAmount ?? 0,
      pricePreVat: order.marketplaceItemPriceWithoutVat ?? 0,
    },
    company: {
      name: order.company.name ?? '',
      code: order.company.code ?? '',
      parentCompany: order.company.parentCompany.id
        ? {
            id: order.company.parentCompany.id,
            name: order.company.parentCompany.name ?? '',
          }
        : null,
      contacts:
        order.company.contactsV2?.map((contact) => ({
          id: contact.id,
          email: contact.email,
          phone: contact.phone,
          fullName: [contact.firstName, contact.lastName]
            .filter(Boolean)
            .join(' '),
        })) ?? [],
    },
    employee: {
      id: order.employee?.id ?? '',
      language: order.employee?.language ?? 'EN',
    },
    accessHistory: order.accessHistory ?? [],
    voucher: order.voucher
      ? {
          id: order.voucher.id,
          sequence: order.voucher.sequence,
          purchasePrice: order.voucher.purchasePrice,
          purchaseReference: order.voucher.purchaseReference,
          qrCode: order.voucher.qrCode ?? '',
          barCode: order.voucher.barCode ?? '',
          code: order.voucher.code ?? '',
          pin: order.voucher.pin ?? '',
          serial: order.voucher.serial ?? '',
          url: order.voucher.url ?? '',
        }
      : null,
  };
};

const convertAdminOrders = (orders: IAdminOrder[]): AdminOrder[] => {
  return orders.map(convertAdminOrder);
};

export const converters = {
  getAdminOrders: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getAdminOrders>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertAdminOrders(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getAdminOrder: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getAdminOrder>>) => {
    return convertAdminOrder(data);
  },
  getFilterValues: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getFilterValues>>) => {
    return data;
  },
};
