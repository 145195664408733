import { IShopItem } from 'types/income';
import i18n from 'i18n';
import { converters as tableConverters } from 'store/table';
import { converters as filesConverters } from 'store/files';
import { loaders } from './shop.loaders';
import { BaseShopItem, ShopItem } from './shop.types';

export const convertBaseShopItem = (item: IShopItem): BaseShopItem => {
  return {
    id: item.id,
    name: item.name,
    image: item.image ? filesConverters.getUploadedFile(item.image) : null,
    sku: item.sku,
    vatRate: item.vatRate,
    currency: item.currency,
    countries: item.countries,
    cities: item.cities,
    countriesAlpha2: item.countriesAlpha2,
    benefitPlanTypes: item.benefitPlanTypes ?? [],
    categories: item.categories,
    status: item.status,
    translations: item.translations.map((translation) => ({
      language: translation.language,
      id: translation.id,
      title: translation.title,
      description: translation.description,
    })),
    supplier: {
      id: item.supplier.id,
      name: [
        item.supplier.name,
        item.supplier.isParentCompany ? `(${i18n.t('common.private')})` : null,
      ]
        .filter(Boolean)
        .join(' '),
      logo: item.supplier.logo
        ? filesConverters.getUploadedFile(item.supplier.logo)
        : null,
      termsAndConditionsLink: item.supplier.termsAndConditionsLink ?? '',
      additionalInfo: item.supplier.additionalInfo ?? '',
      isParentCompany: item.supplier.isParentCompany,
    },
    priority: item.priorityIndex,
    createdAt: item.creationDate,
  };
};

export const convertShopItem = (item: IShopItem): ShopItem => {
  return {
    ...convertBaseShopItem(item),
    prices:
      item.prices
        ?.toSorted((a, b) => (a.index ?? 0) - (b.index ?? 0))
        .map((price) => ({
          id: price.id,
          price: price.price,
          priceRangeFrom: price.priceRangeFrom,
          priceRangeTo: price.priceRangeTo,
          priceType: price.priceType,
          discountPercentage: price.discountPercentage,
          translations: item.translations.map((trans) => {
            const priceTranslation = price.translations?.find(
              (priceTrans) => priceTrans.language === trans.language,
            );
            return {
              language: trans.language,
              description: priceTranslation?.description ?? '',
            };
          }),
          inventory: price.inventory ?? 0,
          index: price.index ?? 0,
        })) ?? [],
  };
};

const convertShopItems = (items: IShopItem[]): ShopItem[] => {
  return items.map(convertShopItem);
};

export const converters = {
  getShopItems: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getShopItems>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertShopItems(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
};
